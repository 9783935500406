import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import TempEntry from '../../TempEntry'
const MyPracticeDataLoader = React.lazy(() => import('components_v2/MyPractice/MyPracticeDataLoader'))

/****************************************
 * TODO: React Refactor
 *  This is the temporary Entry Point for Community.
 *  Once we switch over to full React, we can do away with this page.
 *
 *  This entrypoint uses the legacy withRedux due to some deep integration going on in the video component.
 */

export const MyPracticeTempEntry = ({ user, tab, ...props }) => {
  return (
    <>
    <TempEntry
      user={user}
      deprecated
      mt={['0px', null, '0px']}
      controls={{
        footerFlush: {
          mobile: true,
          desktop: true
        }
      }}
    >
      <Suspense fallback={<></>}>
          <MyPracticeDataLoader username={user.username} selected_tab={tab} {...props} />
      </Suspense>
      </TempEntry>
    </>
  )
}

MyPracticeTempEntry.propTypes = {}

export default MyPracticeTempEntry
